<template>
  <div class="withdrawal">
    <div class="withdrawal-content flex">
    <div class="withdrawal-action">
      <div class="m-title">提现平台</div>
      <div class="balance-box">
        <div class="ktx-balance">可提现余额：</div>
        <div class="balance"><span>￥</span>{{ commission }}</div>
        <!-- <div class="sum-sy">
          <div class="label">累计收益：</div>
          <div class="value">￥8521.00</div>
          <div class="label">累计已提现：</div>
          <div class="value">￥1888.00</div>
        </div> -->
      </div>

      <!-- <div class="module-box">
        <div class="label">可提现金额：{{ commission }}</div>
      </div> -->
      <div class="module-box">
        <div class="label">
          <b>*</b>本次提现：
          <div class="money">
            <el-input v-model="form.money" size="small" class="ipt" @change="moneyChange" />元
          </div>
          <div class="all-money" @click="allTx()">全部提现</div>
        </div>
      </div>
      <!-- 提现类型start -->
      <div class="module-box">
        <div class="label">
          <b>*</b>提现类型：
          <el-select v-model="typeValue" placeholder="请选择提现类型" style="width: 132px" @change="changeMethod">
            <el-option v-for="item in accountType" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 提现类型end -->
      <div class="module-box">
        <div class="label module-li">
          <b>*</b>提现账户：
          <!-- 提现账户列表start -->
          <div class="count-list" v-if="accountDrop.length!=0">
            <RadioGroup v-model="form.account_id">
              <li class="flex" v-for="(item,index) in accountDrop" :key="index">
                <Radio :label="item.user_id" > 
                  <template v-if="typeValue==1">
                  {{item.name}}-{{item.account}}
                </template>
                <template v-else>
                  {{item.name}}-{{item.bank_name}}-{{item.account}}
                </template>
                </Radio>
                <p @click="addCount('update',item)">修改</p><span>|</span>
                <p @click="deleteMethod(item.user_id)">删除</p>
              </li>
              <!-- <li class="flex">
              <Radio :label="3">张三-支付宝账号</Radio>
              <p>修改</p><span>|</span><p>删除</p>
            </li>
            <li class="flex">
              <Radio :label="3">张三-支付宝账号</Radio>
              <p>修改</p><span>|</span><p>删除</p>
            </li> -->
            </RadioGroup>
          </div>
          <!-- 提现账户列表end -->
          <div class="all-money" @click="addCount('add')">添加账户</div>
        </div>
      </div>
      <div class="apply-action" @click="applySubmit()">申请提现</div>
    </div>

    <div class="withdrawal-rule"></div>
    <!-- 弹窗 start-->
    <Dialog :title="typeValue==1?(typeOperate=='add'?'添加支付宝账户':'修改支付宝账户'):(typeOperate=='add'?'添加银行卡账户':'修改银行卡账户')"
      :visible.sync="dialogVisible">
      <!-- 自定义内容 start-->
      <!-- 支付宝 start-->
      <div class="alipayContent" v-if="typeValue==1">
        <li class="flex">
          <span>支付宝账号：</span>
          <el-input v-model="alipayList.account" placeholder="请输入支付宝账号"></el-input>
        </li>
        <li class="flex">
          <span>账户名：</span>
          <el-input v-model="alipayList.name" placeholder="请输入支付宝账号名"></el-input>
        </li>
      </div>
      <!-- 支付宝end -->
      <!-- 银行卡start-->
      <div class="alipayContent" v-else>
        <li class="flex">
          <span>开户银行：</span>
          <el-input v-model="bankList.bank_name" placeholder="请输入开户银行"></el-input>
        </li>
        <li class="flex">
          <span>支行名称：</span>
          <el-input v-model="bankList.opening_bank_branch" placeholder="请输入支行名称"></el-input>
        </li>
        <li class="flex">
          <span>开户名称：</span>
          <el-input v-model="bankList.name" placeholder="请输入开户名称"></el-input>
        </li>
        <li class="flex">
          <span>银行卡号：</span>
          <el-input v-model="bankList.account" placeholder="请输入银行卡号"></el-input>
        </li>
      </div>
      <!--银行卡end -->
      <!-- 自定义内容 start-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureMethod">确 定</el-button>
      </span>
    </Dialog>
    <!-- 弹窗 end-->
  </div>
  </div>
</template>
<script>
  import api from "../../http/api";
  import { Message, Dialog, Form, FormItem, Radio, RadioGroup, MessageBox } from "element-ui";
  export default {
    components: { Dialog, Form, FormItem, Radio, RadioGroup },
    data() {
      return {
        alipayList: {
          account: '', //支付宝账号
          name: '',//用户名
        },
        id: '', //用户Id
        bankList: {
          opening_bank_branch: "",
          name: "",
          bank_name: "",
          account: ""
        }, //银行卡列表
        //radio: '',//单选框
        //觉得接口不能这样
        form: {
          account_id: '',
          money: "",
        },
        typeOperate: 'add',//弹窗操作
        typeValue: 1, //提现类型1支付宝2银行卡
        accountType: [],//提现类型
        accountDrop: [], //提现账户
        dialogVisible: false,
        commission: 0,
      };
    },
    async activated() {
      this.getBalance();
      let type = await api.getBankType() //获取提现类型
      if (type.code == 200 && type.response && type.response.length != 0) {
        this.accountType = type.response
      }
      //console.log('提现类型', type)
      this.getAccountDrop();
    },
    methods: {
      //删除
      deleteMethod(id) {
        let that = this;
        MessageBox.confirm('该操作不可逆，确定要删除吗？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback: action => {
            if (action == 'confirm') {
              api.deleteBanlUser({
                id
              }).then(res => {
                that.getAccountDrop()
                Message({
                  message: res.response.success ? "删除成功~" : '删除失败~',
                  type: res.response.success ? "success" : 'error',
                });
                 //console.log('删除res', res)
              })
            }
          }
        })

      },
      //切换
      changeMethod() {
        this.getAccountDrop()
      },
      // 弹窗操作
      async sureMethod() {
        let that = this;
        let data
        if((!that.alipayList.account||!that.alipayList.name)&&that.typeValue == 1){
            return Message({
            message: `${!that.alipayList.account?'支付宝账户':'账户名'}不能为空~`,
            type: "error",
          });
          } 
      if((!that.bankList.opening_bank_branch||!that.bankList.name||!that.bankList.bank_name||!that.bankList.account)&&that.typeValue == 2){
        return Message({
            message: `${!that.bankList.opening_bank_branch?'支行名称':(!that.bankList.name?'开户名称':(!that.bankList.bank_name?'银行名称':'银行卡卡号'))}不能为空~`,
            type: "error",
          });
        }
         data = {
            id: that.id,
            bank_type: that.typeValue,
            transfer_account: that.typeValue == 1 ? that.alipayList : that.bankList,
            extend_data: '',
          }
          let result = that.typeOperate == 'add' ? await api.addBankUser(data) : await api.updateBankUser(data)
          Message({
            message: result.response.success ? `${that.typeOperate == 'add' ? '添加' : '修改'}成功` : '',
            type: result.response.success ? "success" : 'error',
          });
          that.getAccountDrop();
          that.dialogVisible = false;
          //console.log('result', result)
        
      },
      getBalance() {
        api.getAccountCommission().then((res) => {
          if (res.code == 200) {
            this.commission = res.response.commission;
          }
        });
      },
      // 获取账户下拉
      getAccountDrop() {
        console.log('typeValue', this.typeValue)
        api.getAccountDrop({ bank_type: this.typeValue }).then((res) => {
          if (res.code == 200 && res.response && res.response.length != 0) {
            this.accountDrop = res.response.map(item => {
              return this.typeValue ==1?{
                user_id: item.id,
                name: item.transfer_account.name,
                account: item.transfer_account.account
              }:{
                user_id: item.id,
                name: item.transfer_account.name,
                bank_name: item.transfer_account.bank_name,
                opening_bank_branch:item.transfer_account.bank_name,
                account: item.transfer_account.account
              }
            })
            this.form.account_id = this.accountDrop[0].user_id
            //this.accountDrop = res.response;
          } else {
            this.accountDrop = []
          }
        });
      },
      addCount(key, data) {
        let that = this;
        that.typeOperate = key;
        that.id = data && data.user_id ? data.user_id : 0
        that.typeValue == 1 ? (that.alipayList = {
          account: key == 'add' ? '' : data.account, //支付宝账号
          name: key == 'add' ? '' : data.name,//用户名
        }) : (that.bankList = {
          opening_bank_branch: key == 'add' ? '' : data.opening_bank_branch,
          name: key == 'add' ? '' : data.name,
          bank_name: key == 'add' ? '' : data.bank_name,
          account: key == 'add' ? '' : data.account,
        })
        that.dialogVisible = true;
      },
      // 输入提现金额
      moneyChange(val) {
        if (val > this.commission) {
          this.form.money = this.commission;
        }
      },
      // 全部提现
      allTx() {
        if (this.commission <= 0)
          return Message({
            message: "暂无可提现金额",
            type: "error",
          });
        this.form.money = this.commission;
      },
      // 申请提现
      applySubmit() {
        //console.log('radio',this.radio)
        // return;
        if (!this.form.account_id) {
          return Message({
            message: "请选择提现账户",
            type: "error",
          });
        }
        if (!this.form.money) {
          return Message({
            message: "请输入提现金额",
            type: "error",
          });
        }
        api.applyAccount(this.form).then((res) => {
          this.getBalance();
          this.form.money='';
           Message({
              message: res.code == 200?"提现成功":res.msg,
              type: res.code == 200?"success":'error',
            });
       
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .count-list {

    /* width:calc(100% - 150px); */
    li {
      align-items: flex-start;
      margin: 5px 0;

      span {
        margin: 0 5px;
        color: #999;
      }

      p {
        color: #999;
      }
    }

    /deep/ .el-radio-group {
      font-size: 14px;
    }

    /deep/ .el-radio {
      margin-right: 15px;
    }
  }

  .withdrawal {
    
    height: 100%;
    padding: 25px 33px;
    border: 1px solid #DCDCDC;
overflow-y: auto;
.withdrawal-content{

}
    .withdrawal-action {
      flex: 1;

      .m-title {
        font-size: 18px;
        color: #000000;
        margin-bottom: 16px;
      }

      .balance-box {
        background: #f5f5f5;
        border: 1px solid #dcdcdc;
        padding: 10px 20px;
        margin-bottom: 20px;

        .ktx-balance {
          font-size: 16px;
          color: #000000;
        }

        .balance {
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fb3838;
          margin: 8px 0;

          span {
            font-size: 16px;
          }
        }

        .sum-sy {
          display: flex;
          align-items: center;

          .label {
            font-size: 14px;
            color: #888888;
          }

          .value {
            font-size: 14px;
            color: #fb3838;
            margin-right: 23px;
          }
        }
      }

      .module-box {
        padding: 10px 0;

        .label {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #000000;

          b {
            color: #fb3838;
            font-size: 14px;
            font-weight: 600;
            margin-right: 1px;
          }

          .money {
            display: flex;
            align-items: center;
            width: 132px;
            height: 29px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            font-size: 14px;
            color: #888888;
            padding-right: 10px;
          }

          .all-money {
            /* background: #3355fb; */
            font-size: 14px;
            color: #3355fb;
            margin-left: 10px;
            padding: 0px 10px;
            /* line-height:30px; */
            border-radius: 4px;
            cursor: pointer;
            margin-top: 1px;
          }
        }

        .module-li {
          align-items: flex-start;
        }
      }

      .apply-action {
        width: 174px;
        height: 37px;
        background: linear-gradient(180deg, #3f90f9, #314bfb);
        margin-top: 28px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 37px;
        text-align: center;
      }
    }

    .withdrawal-rule {
      flex: 1;
    }
  }

  /deep/ .el-input__icon {

    line-height: 27px;
  }

  .alipayContent li {
    margin: 20px 0;

    span {
      width: 90px;
    }

    /deep/ .el-input {
      width: calc(100% - 100px);

      .el-input__inner {
        height: 35px !important;
        line-height: 35px;

      }
    }
  }

  /deep/ .el-dialog__body {
    padding-top: 0px;
  }
</style>
<style>
  .el-input--small .el-input__inner {
    border: 0;
  }

  .el-input__inner {
    height: 27px !important;
    line-height: 27px;
    outline: none;
    border-radius: 0;
  }
</style>